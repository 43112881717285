html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.container {
  width: 100vw;
  min-height: 100vh;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.navbar {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #DEE2FF;
  padding: 15px 7%;
}

.logo {
  width: 80px;
  cursor: pointer;
}

ul {
  flex: 1;
  list-style: none;
  text-align: right;
}

ul li {
  display: inline-block;
  margin: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
}

ul li::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #EDF7B5;
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

ul li:hover::after {
  width: 100%;
}

ul li a {
  text-decoration: none;
  color: inherit;
}

ul li:hover a {
  color: #007bff;
}

footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 10px 0;
}