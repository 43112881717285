/* General Styling */
body {
    font-family: 'Arial', sans-serif;
  }
  
  .team-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .team-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .team-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .team-member {
    flex: 1 1 calc(33.333% - 20px); /* Three items per row with spacing */
    max-width: calc(33.333% - 20px);
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
  }
  
  .team-member-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .team-member h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  
  .team-member p {
    font-size: 1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .team-member {
      flex: 1 1 calc(50% - 20px); /* Two items per row */
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .team-member {
      flex: 1 1 100%; /* One item per row */
      max-width: 100%;
    }
  }