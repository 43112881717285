html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.container {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.main-content {
  flex: 1;
  width: 100%;
  padding: 20px;
}


.footer {
  background-color: #011936;
  padding: 20px 0;
  text-align: center;
  color: white;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

.footer-section {
  flex: 1;
  padding: 10px;
}

.footer-section h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-section p {
  margin: 5px 0;
}

.footer-icon {
  font-size: 24px;
  color: white;
  transition: color 0.3s;
  margin: 0 10px;
}

.footer-icon:hover {
  color: #C0DFA1;
}

.footer-bottom {
  border-top: 1px solid #ccc;
  padding: 10px 0;
}

.footer-bottom p {
  margin: 5px 0;
}

.heart {
  color: red;
}