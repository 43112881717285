.navbar {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #002147;
  padding: 15px 7%;
  position: relative;
  z-index: 1000;
}

.logo {
  width: 50px;
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  z-index: 1002;
}

.nav-menu {
  flex: 1;
  list-style: none;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.nav-item {
  display: inline-block;
  margin: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
}

.nav-item::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #C0DFA1;
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.nav-item:hover::after {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: white;
}

.nav-item:hover .nav-link {
  color: #C0DFA1;
}

.menu-overlay {
  display: none;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .menu-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    opacity: 0;
    transition: all 0.3s ease;
    background-color: #002147;
    z-index: 1001;
    padding-top: 60px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
  }

  .nav-item {
    margin: 20px 0;
    text-align: center;
  }

  .nav-link {
    font-size: 24px;
    padding: 10px 0;
    display: block;
  }
}