body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-container {
  background-color: #002147; /* Oxford Blue Background */
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px; /* Add sparks of white color for additional background */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%; /* Ensure the container takes full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.title-section {
  text-align: center;
  margin-bottom: 50px;
  padding: 0 20px; /* Add padding to ensure text doesn't touch the edges */
  width: 100%; /* Ensure the section takes full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.title-section h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.title-section p {
  font-size: 1.5em;
  font-weight: bold;
}

.registration-section {
  background-color: #011936; /* Slightly darker blue for contrast */
  color: white;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto; /* Center the section */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.registration-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.registration-section p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.registration-section ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: center;
}

.registration-section ul li {
  font-size: 1.2em;
  color: #EDF7B5; /* Brighter yellow text */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.prizes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prize-icon {
  margin-right: 10px;
  color: #FFD700; /* Gold color for the trophy icon */
}

.registration-section a {
  color: #EDF7B5; /* Brighter yellow text */
  text-decoration: underline;
  font-weight: bold;
}

.registration-section a:hover {
  color: #ffeb3b; /* Lighter yellow on hover */
}

.countdown-timer {
  text-align: center;
  margin-bottom: 20px;
  width: 100%; /* Ensure the section takes full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.countdown-timer h3 {
  font-size: 2em;
  margin-bottom: 10px;
}

.countdown {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Ensure items wrap to the next line if they exceed the width */
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-time {
  font-size: 2.5em;
  font-weight: bold;
}

.countdown-label {
  font-size: 1em;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .title-section h1 {
    font-size: 1.8em; /* Further adjust font size for smaller screens */
  }

  .title-section p {
    font-size: 1em; /* Further adjust font size for smaller screens */
  }

  .registration-section h2 {
    font-size: 1.8em; /* Further adjust font size for smaller screens */
  }

  .registration-section p,
  .registration-section ul li {
    font-size: 0.9em; /* Further adjust font size for smaller screens */
  }

  .countdown-timer h3 {
    font-size: 1.2em; /* Further adjust font size for smaller screens */
  }

  .countdown-time {
    font-size: 1.8em; /* Further adjust font size for smaller screens */
  }

  .countdown-label {
    font-size: 0.7em; /* Further adjust font size for smaller screens */
  }
}