.resources-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .resources-title {
    text-align: center;
    font-size: 2.25rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  
  .resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .resource-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .resource-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .resource-header {
    background-color: #f4f6f7;
    padding: 1rem;
    border-bottom: 1px solid #e0e6e9;
  }
  
  .resource-header-title {
    font-size: 1.25rem;
    color: #2c3e50;
    margin: 0;
    font-weight: 600;
  }
  
  .resource-content {
    flex-grow: 1;
    padding: 1rem;
  }
  
  .resource-description {
    color: #7f8c8d;
    line-height: 1.6;
    margin: 0;
  }
  
  .resource-footer {
    padding: 1rem;
    border-top: 1px solid #e0e6e9;
  }
  
  .resource-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .resource-button:hover {
    background-color: #2980b9;
  }
  
  .button-icon {
    width: 1rem;
    height: 1rem;
  }
  
  @media (max-width: 768px) {
    .resources-grid {
      grid-template-columns: 1fr;
    }
  }